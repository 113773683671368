// 
// COLORS ---------
// 

:root {
  --nav-bg: #f8f8f8;
  --nav-bg-scrolled: #fff; 
  --nav-bg-shadow: 0 5px 10px rgba(0, 0, 0, .02);
}

$nav-bg: #f8f8f8;
$nav-bg-scrolled: #fff;

$background-color: #f9f9f9;
$background-color-01: #f2f2f2;

// Paragraphs
$paragraph-color: #4a4a4a; // base

// Heading
$heading-color: #1a1a1a;

// Border 
$border-color: #e1e1e1;
$border-color-focus: #c5c5c5;

// General
$white: #fff;
$black: #000;
$off-black: #303030;
$off-white: #f0f0f0;
$red: rgba(247, 45, 45, 0.986);
$green: rgb(59, 150, 32);
$yellow: rgb(228, 165, 31);
$gray-01: #3a3a3a;
$gray-10: #818181;
$gray-20: #b6b6b6;

// Buttons
$button-color: #101010;
$button-hover: lighten($button-color, 10%);

// Social
$color-facebook: #0078ff;
$color-facebook-hover: darken($color-facebook, 5%);
$color-github: #24292e;
$color-github-hover: lighten($color-github, 5%);

$color-success: #000;
