.checkout {
  width: 100%;
  animation: slide-up .5s ease;

  @include mobile {
    margin-top: 2rem;
  }
}

.checkout-header {
  display: flex;
  justify-content: center;
  position: sticky;
  top: $nav-height;
  background: $background-color;
  padding: 1rem 0;
  margin-bottom: 3rem;
  z-index: 1;

  @include mobile {
    top: 5rem;
  }
}

.checkout-items {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;

  .basket-item {
    margin: .5rem 1rem;

    @include mobile {
      margin: 0;
    }
  }
}

.checkout-header-menu {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: auto;
  position: relative;

  @include mobile {
    width: 100%;
    margin: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    margin: auto;
    width: 85%;
    height: 3px;
    background: transparentize($border-color, .5);
  }
}

.checkout-header-list {
  display: flex;
  justify-content: center;
}

.checkout-header-item {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.checkout-header-step {
  margin: 0;
  padding: $m-size;
  background: $border-color;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-10;
}

.checkout-header-subtitle {
  color: $gray-20;
  margin-top: $s-size;
  margin-bottom: 0;
}

.is-active-step .checkout-header-step {
  background: $black;
  color: $white;
}

.is-done-step .checkout-header-step {
  background: $border-color-focus;
  color: $white;
}

.is-active-step .checkout-header-subtitle {
  color: $black;
}

.checkout-action {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    justify-content: center;
    flex-direction: column;
    text-align: center;

    button {
      display: block;
      width: 100%;
    }
  }
}

.checkout-step-1 {
  width: 80rem;
  margin: auto;

  @include tablet {
    width: 100%;
  }
}

.checkout-step-2 {
  width: 70rem;
  margin: auto;

  @include tablet {
    width: 100%;
  }
}

.checkout-step-3 {
  width: 70rem;
  margin: auto;

  @include tablet {
    width: 100%;
  }
}

.checkout-shipping-wrapper {
  display: flex;
  align-items: center;
}

.checkout-shipping-form {
  width: 100%;
}

.checkout-field {
  width: 100%;
  margin: 0 $s-size;
  
  @include mobile {
    margin: 0
  }
}

.checkout-fieldset {
  display: flex;
  align-items: center;

  & > div {
    margin-bottom: $s-size;
  }

  @include mobile {
    flex-direction: column;
  }
}

.checkout-fieldset-collapse {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 97px;
  overflow: hidden;
  transition: all .5s ease;
  opacity: .6;

  &:hover {
    opacity: 1;
  }
}

.checkout-checkbox-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $m-size;
  border: 1px solid $border-color;
  background: #f1f1f1;
}

.checkout-shipping-action {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    // flex-direction: column;
    padding: $m-size 0;

    button {
      width: 50%;
      display: block;
      margin: $s-size 0;
    }
  }
}

.checkout-collapse-sub {
  width: 100%;
  padding: $m-size;
  border: 1px solid $border-color;
  background: $white;
}

.checkout-cards-accepted {
  .payment-img {
    margin: 0 2px;
  }
}

.payment-img {
  width: 48px;
  height: 32px;
  background-size: 300px 200px;
  filter: grayscale(1);
  border-radius: 2px;
}

.payment-img-paypal {
  background-position: 103px 115px;
}

.payment-img-visa {
  background-position: -55px -15px;
}

.payment-img-mastercard {
  background-position: 103px -15px;
}

.payment-img-express {
  background-position: -126px -15px;
}

.payment-img-maestro {
  background-position: -55px 47px;
}

.payment-img-discover {
  background-position: 175px 115px;
}