// 
// BASE STYLING ---------
//
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  min-height: 100vh;
  font-family: 'Tajawal', Helvetica, Arial, sans-serif;
  font-size: $baseFontSize;
  background: $background-color;
  font-weight: bold;
  overflow-x: hidden;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}   

p {
  color: $paragraph-color;
  // font-weight: 300;
  line-height: $line-height;
}

strong { 
  font-weight: bold;
}

span {
  color: $paragraph-color;
  font-size: $font-small;
  position: relative;
}

h1,
h2,
h3, 
h4, 
h5,
h6 {
  font-family: 'Tajawal', Helvetica, Arial, sans-serif;
  color: $heading-color;
}

// body::-webkit-scrollbar {
//   width: 8px;
// }
 
// body::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.2);
// }
 
// body::-webkit-scrollbar-thumb {
//   background-color: $heading-color;
//   outline: 1px solid lighten($heading-color, 10%);
// }

#app {
  width: 100%;
}

.content {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: $top $pad-desktop;
  display: flex;
  animation: fadeIn .5s ease;

  @media (max-width: $mobile) {
    padding: 5rem $m-size;
    flex-direction: column;
  }
}

.content-admin {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  position: relative;
  padding-top: $xxl-size;
  overflow: hidden;
  display: flex;
}

.content-admin-wrapper {
  overflow-y: scroll;
  flex-grow: 1;
  padding: $m-size 0;
  padding-top: 0;
  animation: fadeIn .5s ease;
  position: relative;
}
