select {
  font-size: $font-small;
  color: $paragraph-color;
  padding: 7px $m-size;
  background: transparent;
  font-weight: bold;
  border: 1px solid $border-color;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
  linear-gradient(45deg, transparent 50%, $border-color 50%),
  linear-gradient(135deg, $border-color 50%, transparent 50%),
  linear-gradient(to right, $border-color, $border-color);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 3.2em) 0.2em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.8em;
  background-repeat: no-repeat;

  &:focus {
    background: $background-color;
    border: 1px solid $border-color-focus;
    background-image:
    linear-gradient(45deg, $border-color-focus 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, $border-color-focus 50%),
    linear-gradient(to right, $border-color-focus, $border-color-focus);
    background-position:
      calc(100% - 15px) 1em,
      calc(100% - 20px) 1em,
      calc(100% - 3.2em) 0.2em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
    outline: none;
  }
}

option {
  font-weight: bold;
}