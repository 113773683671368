.mobile-navigation {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  // background: $background-color;
  background: #fff;
  z-index: map-get($z-index, 'navigation');
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
}

.mobile-navigation-main {
  width: 100%;
  height: 50px;
  padding: 0 $s-size;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.mobile-navigation-sec {
  display: flex;
  align-items: center;
  padding: 5px $s-size;
}

.mobile-navigation-menu {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  .user-nav {
    height: 100%;
    margin: 0;
  }

  .user-nav h5 {
    display: none;
  }
}

.mobile-navigation-item {
  // width: 50%;
  height: 100%;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid $border-color;
}

.mobile-navigation-logo {
  flex-grow: 1;
  padding-left: .5rem;
  margin-right: $xl-size;
}

.mobile-navigation-logo img {
  width: 15rem;
  height: inherit;
  object-fit: contain;
}

.mobile-navigation-logo a {
  display: block;
  height: 100%;
}

.mobile-navigation-search {
  width: 50%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $paragraph-color;
}

.mobile-navigation-search-title {
  margin: .6rem;
  font-weight: normal;
}
