@mixin bezier-transition($prop: all, $speed: .5s, $func: cubic-bezier(.77,0,.175,1) ) {
  // -webkit-transition: all $speed cubic-bezier(.4, 0, .2, 1);
  -webkit-transition: $prop $speed $func;
  -moz-transition: $prop $speed $func;
  -o-transition: $prop $speed $func;
  -ms-transition: $prop $speed $func;
  transition: $prop $speed $func;
}


@mixin l-desktop {
  @media (min-width: $l-desktop) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) and (max-width: $l-desktop) {
    @content;
  }
}


@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}