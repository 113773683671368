// 
// SIZES ---------
// 

$nav-height: 6rem;

$xs-size: 1rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$xxl-size: 5.6rem;

$top: 10rem;
$top-mobile: 8.5rem;
$bottom: 15rem;
$line-height: 2.4rem;

$pad-desktop: 10rem;