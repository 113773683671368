.preloader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: $background-color;
    animation: fadeIn .5s ease;

    img {
        width: 200px;
        height: 120px;
        object-fit: contain;
    }
}

.loader {
    width: 100%;
    height: inherit;
    background: $background-color-01;
    position: relative;
    padding: $m-size;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: fadeIn .3s ease;

    @include mobile {
        min-height: 80vh;
    }
}

.logo-symbol {
    width: 70px;
    height: 70px;
    animation: rotate 1s ease infinite;

    .fill-white {
        fill: #fff;
    }
}

@keyframes rotate {
    90% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 