
.sidebar {
  width: 250px;
  // height: 100vh;
  position: relative;
  left: 0;
  top: 0;
  padding-top: 5px;
  // background: $white;
  // border: 1px solid $border-color;
  margin-right: $s-size;
}