.banner {
    width: 100%;
    height: 40rem;
    margin-top: 4rem;
   
    background: #f3f3f3;
    display: flex;

    @include mobile {
        height: auto;
        flex-direction: column;
    }
}

.banner-desc {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 5rem;
    flex-basis: 50%;

    @include mobile {
        padding: 2rem;
    }

    h1 {
        font-size: 4.8rem;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        width: 80%;

        @include mobile {
            width: 100%;
            font-size: 4rem;
        }
    }
}

.banner-text {
    font-size: 1.5rem;
    line-height: 2.5rem;
}

.banner-img {
    position: relative;
    width: 100%;
    height: 100%;
    flex-basis: 50%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translateX(8rem);

        @include mobile {
            transform: translateX(0);
        }
    }
}

.banner-img-left {
    position: relative;
    width: 100%;
    height: 100%;
    flex-basis: 50%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translateX(-8rem);

        @include mobile {
            transform: translateX(0);
        }
    }
}