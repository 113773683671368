.user-profile {
  display: flex;
  margin: auto;

  @include mobile {
    width: 100%;
  }
}

.user-profile-block {
  // width: 600px;
  width: 100%;
  height: auto;
  // padding: $m-size;

  // @include mobile {
  //   padding: 0;
  // }
}

.user-profile-banner-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background: lighten($border-color, 3%);
  overflow: hidden;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.user-profile-banner {
  width: 100%;
  height: 150px;
  position: relative;

  @include mobile {
    height: 100px;
  }
}

.user-profile-banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}

.user-profile-avatar-wrapper {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid $white;
  position: absolute;
  left: $m-size;
  bottom: 30%;
  position: relative;
  background: $border-color;
  overflow: hidden;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.user-profile-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.user-profile-edit {
  position: absolute;
  right: $s-size;
  bottom: -12%;
}

.user-profile-details {
  padding: 9.5rem $m-size $l-size;

  & > div {
    margin-bottom: $s-size;
  }
}

.user-profile-name {
  text-transform: capitalize;
}