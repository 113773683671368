.pill {
    background: $border-color;
    padding: 0.8rem $m-size;
    border-radius: $m-size;
    display: inline-block;
    margin: 10px 5px;
    position: relative;
    text-align: center;

    &:hover {
        background: $border-color-focus;
    }
}

.pill-title {
    margin: 0;
}

.pill-content {
    max-width: 25rem;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pill-remove {
    position: absolute;
    right: $s-size;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
}

.pill-wrapper {
    text-align: center;

    @include mobile {
        display: inline-block;
    }
}