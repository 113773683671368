.item {
  position: relative;
  padding: $s-size;
  border: 1px solid transparent;
  
  &:nth-child(even) {
    background: $white;
  }
  
  &:hover {
    border: 1px solid $border-color-focus;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, .1);
    z-index: 1;
  }
}

.item-products {
  padding: 0;
}

.item:hover > .item-action {
  display: flex;
}

// reset styles for loading item
.item-loading {
  &:nth-child(even) {
    background: none;
  }
}

.item-loading:hover {
  border: none;
  box-shadow: none;
}

// ---- 

.item-action {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: $s-size;
  background: $white;
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  display: none;
}

.item-action-confirm {
  width: 350px;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: $s-size;
  display: flex;
  align-items: center;
  z-index: 1;
  display: none;

  h5 {
    flex-grow: 1;
  }
}

.item-active {
  border: 1px solid $border-color;
}

.item-active:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, .3), $white 90%);
}

.item-active .item-action-confirm,
.item-active .item-action {
  display: flex;
}

.item-img-wrapper {
  width: 80px;
  height: 50px;
  margin-left: $s-size;
}

.item-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
