.footer {
  position: relative;
  padding: 0 $xxl-size;
  margin-top: $xl-size;
  background: $off-white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 10rem 2rem;

  @include mobile {
    padding: 0;
    padding-top: 2.5rem;
    flex-direction: column;
    text-align: center;
  }
}

.footer-logo {
  width: 15rem;
  height: 6rem;
  object-fit: contain;
}

.footer-col-1 {
  flex-basis: 33%; 

  @include mobile {
    flex-basis: none;
    order: 1;
    text-align: center;
  }
}

.footer-col-2 {
  padding: 3rem 0;
  text-align: center;
  flex-basis: 33%;
  align-self: flex-end;

  @include mobile {
    flex-basis: none;
    order: 3;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.footer-col-2 span {
  font-size: 1.5rem;

  @include mobile {
    font-size: 1.3rem;
  }
}

.footer-col-3 {
  flex-basis: 33%;
  text-align: right;

  @include mobile {
    flex-basis: none;
    order: 2;
    text-align: center;
  }
}

@include mobile {
  .footer-col-1,
  .footer-col-2,
  .footer-col-3 {
    width: 100%;
    margin: $s-size 0;
  }
}

.about-us-link {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  cursor: pointer;
}