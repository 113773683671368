.price-range {
  width: 100%;
  position: relative;
  // box-shadow: inset 0 2px 5px rgba(0, 0, 0, .2);
}

.price-range-control {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  margin-bottom: 3rem;
}

.price-range-slider {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;

  &:nth-child(1):hover {
    background: red;
  }
}

.price-range-input {
  width: 50%;
  border: none !important;
  font-size: 4rem !important;
  padding: 0 !important;
  flex-grow: 1;
  text-align: center;
}

.price-input-error {
  color: red !important;
  background: transparentize(red, .9);
}

.price-range-scale {
  display: flex;
  padding: $m-size 0;
}

.price-range-price {
  font-size: 12px;
  font-weight: bold;
}

.price-range-price:nth-child(1) {
  flex-grow: 1;
}

// .range-slider svg,
// .range-slider input[type=range] {
//   position: absolute;
//   left: 0;
//   bottom: 0;
// }